<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createOrder"
        v-if="hasPermission('show_create_order')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <!-- <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-input-group>
                <template #prepend> </template>
              </b-input-group>
              <b-form-input id="name" v-model="name" placeholder="Order Name" />
            </b-form-group>
            
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row> -->
      <b-table
        responsive="sm"
        :fields="fields"
        :items="orders"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('show_create_order')"
        :per-page="0"
      >
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>

        <template #cell(name)="row">
          {{ row.item.patient_data.full_name }}
        </template>
        <template #cell(is_paid)="row">
          {{ row.item.is_paid == true ? "Paid" : "Unpaid" }}
        </template>

        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>

        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="success"
            pill
            size="sm"
            class="mr-1"
            v-if="hasPermission('show_create_order')"
            @click="viewOrder(row.item)"
          >
            View Details
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <order-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${orderCreateModalCount}`"
    />

    <pending-order-detail-modal
      :order="orderView"
      @modalClosed="onViewOrderModalClosed"
      :key="`view-${PendingOrderDetailModal}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import OrderCreateModal from "@/components/admin/csr/OrderCreateModal.vue";
import PendingOrderDetailModal from "@/components/admin/patient/dashbaord/PendingOrderModal.vue";

export default {
  components: {
    OrderCreateModal,
    PendingOrderDetailModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "patient", label: "Patient ID" },
        { key: "name", label: "Patient Name" },
        { key: "order_number", label: "Order No" },
        { key: "amount", label: "Amount" },
        { key: "is_paid", label: "Status" },
        { key: "created_at", label: "Order Date" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      orders: [],
      order: null,
      OrderCreateModal: 0,
      RoomEditModal: 0,
      orderCreateModalCount: 0,
      roomEditModalCount: 0,
      searchTypes: [{ value: 1, name: "Name" }],
      searchType: null,
      name: "",
      username: "",
      PendingOrderDetailModal: 0,
      PaidOrderDetailModal: 0,
      orderView: null,
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getOrderList: "appData/getOrderList",
    }),
    async filter() {
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            this.username = "";
            break;
          case 2:
            this.name = "";
            break;
        }
      } else {
        this.name = "";
        this.username = "";
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        const res = await this.getOrderList(tempParams);
        this.orders = res.data.results;

        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createOrder() {
      this.roomCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("order-create-modal");
      });
    },
    viewOrder(orderView) {
      this.orderView = orderView;
      this.PendingOrderDetailModal += 1;
      this.$nextTick(() => {
        this.$bvModal.show("pending-order-detail-modal");
      });
    },
    // editRoom(order) {
    //   this.order = order;
    //   this.roomEditModalCount += 1;
    //   this.$nextTick(() => {
    //     this.$bvModal.show("order-edit-modal");
    //   });
    // },
    // async removeRoom(order) {
    //   this.order = order;

    //   this.$swal({
    //     title: "Are you sure?",
    //     icon: "warning",
    //     showCloseButton: true,
    //     showCancelButton: true,
    //     confirmButtonText: "Confirm",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then(async (result) => {
    //     if (result.value) {
    //       try {
    //         const res = await this.deleteRoom({
    //           pk: this.order.id,
    //         });
    //         if (res.status === 200) {
    //           this.$swal({
    //             title: "Order deleted successfully",
    //             icon: "success",
    //           });
    //           if (
    //             (this.totalItems - 1) % this.perPage === 0 &&
    //             this.currentPage !== 1
    //           ) {
    //             this.currentPage -= 1;
    //           } else {
    //             await this.fetchPaginatedData();
    //           }
    //         }
    //       } catch (error) {
    //         this.show = false;
    //         this.displayError(error);
    //       }
    //     } else {
    //       this.show = false;
    //     }
    //   });
    // },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
    onViewOrderModalClosed() {
      this.orderView = null;
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.rooms.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
