<template>
  <b-modal
    id="order-create-modal"
    title="Order Create Modal"
    centered
    hide-footer
    size="xl"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Create Booking</h2>
    </template>
    <validation-observer ref="OrderCreateFormValidation">
      <b-form @submit.prevent>
        <h2 class="text-center">Patient Details</h2>
        <b-row class="borderStyle">
          <b-col cols="3">
            <b-input-group prepend="Email">
              <b-form-input
                id="email"
                v-model="patient.email"
                placeholder="Email"
                @focusout="fetchPatient()"
                type="email"
                required
                @keydown="checkMaxLength($event, 'email', 100)"
              ></b-form-input>
            </b-input-group>
            <span color="red">{{ label }}</span>
          </b-col>

          <b-col cols="3">
            <b-form-input
              id="name"
              v-model="patient.full_name"
              placeholder="Name"
              required
              v-restrict="regex"
            />
          </b-col>

          <b-col cols="3">
            <validation-provider
              #default="{ errors }"
              name="Mobile Number"
              rules="required|integer"
            >
              <b-form-group>
                <b-form-input
                  id="patient_mobile"
                  v-model="patient.mobile"
                  placeholder="Mobile Number"
                  required
                  type="text"
                  @keydown="checkMaxLength($event, 'mobile', 20)"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="3">
            <b-form-input
              id="passport_number"
              v-model="patient.passport_number"
              placeholder="Passport Number"
            />
          </b-col>

          <b-col class="mt-2" cols="3">
            <b-form-input
              id="nationality"
              v-model="patient.nationality"
              v-restrict="regex"
              placeholder="Nationality"
              required
            />
          </b-col>

          <b-col class="mt-2" cols="3">
            <b-form-checkbox
              id="is_consultation"
              v-model="is_consultation"
              name="is_consultation"
              @change="reset()"
            >
              Consultation Required
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="is_consultation == false">
            <h3 class="text-center">Select Services</h3>
            <b-alert class="alertStyle mt-2" show
              ><h4>Select Procedure</h4></b-alert
            >
            <b-row>
              <b-col cols="6">
                <v-select
                  label="doctorLabel"
                  @input="fetchProcedure()"
                  v-model="doctor"
                  placeholder="Select Doctor"
                  :options="formattedDoctors"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
              <b-col cols="6">
                <v-select
                  @input="addService()"
                  label="name"
                  :disabled="procedures.length === 0 ? true : false"
                  v-model="procedure"
                  placeholder="Select Procedure"
                  :options="procedures"
                  multiple
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
            </b-row>

            <b-alert class="alertStyle mt-2" show
              ><h4>Select Tourist Attraction Package</h4></b-alert
            >
            <b-row>
              <b-col cols="6">
                <v-select
                  label="name"
                  @input="fetchTouristPackage()"
                  v-model="place"
                  placeholder="Select Tourist Place"
                  :options="places"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
              <b-col cols="6">
                <v-select
                  label="departure"
                  @input="addService()"
                  :disabled="tourist_packages.length === 0 ? true : false"
                  v-model="tourist_package"
                  placeholder="Select Tourist Package"
                  :options="tourist_packages"
                  multiple
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
            </b-row>

            <b-alert class="alertStyle mt-2" show
              ><h4>Select Room Package</h4></b-alert
            >
            <b-row>
              <b-col cols="6">
                <v-select
                  label="name"
                  v-model="hotel"
                  @input="fetchRooms()"
                  placeholder="Select Hotel"
                  :options="hotels"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
              <b-col cols="6">
                <v-select
                  label="name"
                  @input="addService()"
                  :disabled="rooms.length === 0 ? true : false"
                  v-model="room"
                  placeholder="Select Room"
                  :options="rooms"
                  multiple
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
            </b-row>

            <b-alert class="alertStyle mt-2" show
              ><h4>Select Car Package</h4></b-alert
            >
            <b-row>
              <b-col cols="6">
                <v-select
                  label="name"
                  @input="addService()"
                  v-model="car"
                  multiple
                  placeholder="Select Car Service"
                  :options="cars"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
              <b-col cols="6"> </b-col>
            </b-row>

            <b-alert class="alertStyle mt-2" show
              ><h4>Select Interprator Package</h4></b-alert
            >
            <b-row>
              <b-col cols="6">
                <v-select
                  label="name"
                  @input="addService()"
                  v-model="interprator"
                  multiple
                  placeholder="Select Interprator Service"
                  :options="interprators"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
              <b-col cols="6"> </b-col>
            </b-row>

            <b-alert class="alertStyle mt-2" show
              ><h4>Select Security Package</h4></b-alert
            >
            <b-row>
              <b-col cols="6">
                <v-select
                  label="name"
                  @input="addService()"
                  v-model="security"
                  multiple
                  placeholder="Select Security Package"
                  :options="securitys"
                  autoscroll
                  :clearable="true"
                ></v-select>
              </b-col>
              <b-col cols="6"> </b-col>
            </b-row>
          </b-col>

          <!-- Consultation services -->
          <b-col v-else>
            <h3 class="text-center">Select Services</h3>
            <b-alert class="alertStyle mt-2" show
              ><h4>Select Doctor for consultation</h4></b-alert
            >
            <b-row>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Select Doctor"
                  rules="required"
                >
                  <b-form-group>
                    <v-select
                      label="doctorLabel"
                      @input="addConsultationService()"
                      v-model="consultation"
                      placeholder="Select Doctor"
                      :options="formattedDoctors"
                      autoscroll
                      :clearable="true"
                    ></v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Date"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-datepicker
                      v-model="date"
                      :min="minDate"
                    ></b-form-datepicker>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Time"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-timepicker
                      v-model="time"
                      locale="en"
                    ></b-form-timepicker>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="pr-2 pl-2">
            <h3 class="text-center">Services Detail</h3>
            <b-table
              class="mt-2"
              striped
              hover
              :items="selectedServices"
              :fields="selectedServicesFields"
            >
              <template #cell(sno)="row">
                <span>{{ row.index + 1 }}</span>
              </template>

              <template #cell(days)="row">
                <span v-show="consultation.name">1</span>
                <b-input-group
                  style="width: 150px"
                  :hidden="consultation.name ? true : false"
                >
                  <b-input-group-prepend>
                    <b-button @click="incrementDays(row.item)">+</b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    class="text-center"
                    type="number"
                    v-model="row.item.days"
                    min="0"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button @click="decrementDays(row.item)">-</b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-table>
            <h3 class="text-center colored-bg">
              Total Amount: {{ totalAmount }}
            </h3>
          </b-col>
        </b-row>

        <b-form-group class="mt-2 text-right">
          <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1"
            @click="validationForm"
          >
            Place Order
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-form-group class="text-right">
        <b-button
          type="submit"
          variant="primary"
          pill
          class="mr-1"
          @click="validationForm"
        >
          Place Order
        </b-button>
        <b-button type="reset" variant="outline-secondary" @click="reset" pill>
          Reset
        </b-button>
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  data() {
    return {
      required,
      hotels: [],
      places: [],
      doctors: [],
      rooms: [],
      cars: [],
      tourist_packages: [],
      interprators: [],
      securitys: [],
      procedures: [],
      hospitals: [],
      patient: {
        id: null,
        full_name: "",
        email: "",
        mobile: "",
        nationality: "",
        passport_number: "",
      },
      patient_id: "",
      doctor: "",
      procedure: [],
      place: "",
      tourist_package: [],
      hotel: "",
      room: [],
      car: [],
      interprator: [],
      security: [],
      label: "",
      email: "",
      is_consultation: true,
      consultation: [],
      selectedServices: [],
      selectedServicesFields: [
        { key: "sno", label: "S.No" },
        { key: "name", label: "Service Name" },
        { key: "amount", label: "Price" },
        { key: "days", label: "No of Days" },
      ],
      totalAmount: 0,
      time: "",
      date: "",
      regex: /^[a-zA-Z ]*$/,
    };
  },
  methods: {
    ...mapActions({
      getHotels: "appData/getHotelsPublic",
      getRooms: "appData/getRoomsPublic",
      getCars: "appData/getRentalCarsPublic",
      getTouristPlaces: "appData/getTouristattractionPublic",
      getTouristPackages: "appData/getTouristtripPublic",
      getInterprators: "appData/getInterpratorServicePublic",
      getSecurity: "appData/getSecurityPublic",
      getHospitals: "appData/getHospitalsPublic",
      getDoctors: "appData/getDoctorsPublicNav",
      getProcedures: "appData/getProceduresPublic",
      getPatient: "appData/getPatientByEmail",
      createOrder: "appData/createOrder",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    async validationForm() {
      const success = await this.$refs.OrderCreateFormValidation.validate();

      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        let orderData = {};

        if (this.is_consultation == false) {
          const orderItems = [];

          if (Array.isArray(this.selectedServices)) {
            this.selectedServices.forEach((item) => {
              let orderItem = {};
              const days = item.days;
              for (let i = 0; i < days; i++) {
                orderItem[item.type] = item.id;
                orderItems.push(orderItem);
              }
            });
          }

          orderData = {
            patient: this.patient.id,
            patient_data: {
              full_name: this.patient.full_name,
              email: this.patient.email,
              mobile: this.patient.mobile,
              passport_number: this.patient.passport_number,
              nationality: this.patient.nationality,
              password: 123,
            },
            order_items: orderItems,
          };
        } else {
          orderData = {
            patient: this.patient.id,
            patient_data: {
              full_name: this.patient.full_name,
              email: this.patient.email,
              mobile: this.patient.mobile,
              passport_number: this.patient.passport_number,
              nationality: this.patient.nationality,
              password: 123,
            },
            is_consultation: this.is_consultation,
            consultation: {
              doctor: this.consultation.id,
              consultation_time: `${this.date} ${this.time}`,
            },
          };
        }

        const res = await this.createOrder(orderData);
        if (res.status === 200) {
          this.$swal({
            title: "Order created successfully",
            icon: "success",
          });
          this.reset();
          this.resetPatientData();
          this.$nextTick(() => {
            this.$bvModal.hide("order-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    async fetchProcedure() {
      if (this.doctor) {
        this.procedure = "";
        const res_procedure = await this.getProcedures({
          hospital: null,
          department: null,
          id: null,
          doctor: this.doctor.id,
        });
        this.procedures = res_procedure.data;
      } else {
        this.procedures = [];
      }
    },
    async fetchTouristPackage() {
      if (this.place) {
        this.touristPackage = "";
        const res_tourist_packages = await this.getTouristPackages({
          touristAttraction: this.place.id,
        });
        this.tourist_packages = res_tourist_packages.data;
      } else {
        this.tourist_packages = [];
      }
    },
    async fetchRooms() {
      if (this.hotel) {
        this.room = "";
        this.rooms = this.hotel.hotel_rooms;
      } else {
        this.rooms = [];
      }
    },
    async fetchPatient() {
      if (this.patient.email) {
        try {
          const res_patient = await this.getPatient({
            email: this.patient.email,
          });
          if (res_patient.data.length > 0) {
            this.patient = res_patient.data[0];
            this.label = "";
          } else {
            this.label = "No Data Found!";
          }
        } catch (e) {
          this.label = "No Data Found!";
        }
      }
    },

    addService() {
      (this.selectedServices = []),
        this.procedure.forEach((service) => {
          this.selectedServices.push({
            id: service.id,
            type: "procedure",
            name: service.name,
            amount: service.amount,
            days: 1,
          });
        });

      this.tourist_package.forEach((service) => {
        this.selectedServices.push({
          id: service.id,
          type: "tourist_trip",
          name: service.departure,
          amount: service.charges,
          days: 1,
        });
      });

      this.room.forEach((service) => {
        this.selectedServices.push({
          id: service.id,
          type: "room",
          name: service.name,
          amount: service.amount,
          days: 1,
        });
      });

      this.car.forEach((service) => {
        this.selectedServices.push({
          id: service.id,
          type: "rental_car",
          name: service.name,
          amount: service.amount,
          days: 1,
        });
      });

      this.interprator.forEach((service) => {
        this.selectedServices.push({
          id: service.id,
          type: "interpreter_service",
          name: service.name,
          amount: service.amount,
          days: 1,
        });
      });

      this.security.forEach((service) => {
        this.selectedServices.push({
          id: service.id,
          type: "security_package",
          name: service.name,
          amount: service.amount,
          days: 1,
        });
      });

      this.calculateTotalAmount();
    },

    addConsultationService() {
      this.selectedServices = [];
      this.selectedServices.push({
        id: this.consultation.id,
        name: this.consultation.name,
        amount: this.consultation.consultation_fee,
        days: 1,
      });

      // this.consultation.forEach(service => {
      //   this.selectedServices.push({
      //     name: service.name,
      //     amount: service.consultation_fee,
      //     days: 1
      //   });
      // });
      this.calculateTotalAmount();
    },

    calculateTotalAmount() {
      this.totalAmount = this.selectedServices.reduce((total, service) => {
        return total + service.amount * service.days;
      }, 0);
    },

    incrementDays(item) {
      item.days += 1;
      this.calculateTotalAmount();
    },
    decrementDays(item) {
      if (item.days > 1) {
        item.days -= 1;
        this.calculateTotalAmount();
      }
    },

    reset() {
      this.patient_id = "";
      this.doctor = "";
      this.procedure = [];
      this.place = "";
      this.tourist_package = [];
      this.hotel = "";
      this.room = [];
      this.car = [];
      this.interprator = [];
      this.security = [];
      this.label = "";
      this.consultation = [];
      this.selectedServices = [];
      this.totalAmount = 0;
      this.time = "";
      this.date = "";
    },
    resetPatientData() {
      this.patient = {
        id: null,
        full_name: "",
        mobile: "",
        nationality: "",
        passport_number: "",
      };
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),

    minDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },

    formattedDoctors() {
      return this.doctors.map((doctor) => {
        return {
          ...doctor,
          doctorLabel: `${doctor.name} - ${doctor.department_name}`,
        };
      });
    },
  },
  async mounted() {
    const res_hotels = await this.getHotels();
    this.hotels = res_hotels.data;

    const res_places = await this.getTouristPlaces();
    this.places = res_places.data;

    const res_doctor = await this.getDoctors();
    this.doctors = res_doctor.data;

    const res_cars = await this.getCars({ name: null, rental_company: null });
    this.cars = res_cars.data;

    const res_interprator = await this.getInterprators();
    this.interprators = res_interprator.data;

    const res_security = await this.getSecurity();
    this.securitys = res_security.data;

    const res_hospital = await this.getHospitals();
    this.hospitals = res_hospital.data;
  },
};
</script>

<style>
.alertStyle {
  padding: 10px 0 2px 10px;
}
.borderStyle {
  border: 2px dotted rgb(207, 207, 207);
  padding: 20px;
  margin: 20px 0 20px 0;
}
.colored-bg {
  background-color: darkgreen;
  color: white;
  padding: 10px;
}
</style>
